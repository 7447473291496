import React, { useState, useEffect } from 'react';
import './AddBillModal.css';

interface AddBillModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (billNumber: string, billDate: string, billAmount: number) => void;
    userId: string;
    initialData?: { billNumber: string; billDate: string; billAmount: number }; // Add initialData prop
}

const AddBillModal: React.FC<AddBillModalProps> = ({ isOpen, onClose, onSave, userId, initialData }) => {
    const [billNumber, setBillNumber] = useState('');
    const [billDate, setBillDate] = useState('');
    const [billAmount, setBillAmount] = useState('');

    useEffect(() => {
        if (initialData) {
            setBillNumber(initialData.billNumber);
            setBillDate(initialData.billDate.slice(0, 10)); // Ensure the date is in YYYY-MM-DD format
            setBillAmount(initialData.billAmount.toString());
        }
    }, [initialData]);

    const handleSave = () => {
        onSave(billNumber, billDate, parseFloat(billAmount));
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Add Bill for User {userId}</h2>
                <div className="form-group">
                    <label htmlFor="billNumber">Bill Number:</label>
                    <input
                        type="text"
                        id="billNumber"
                        value={billNumber}
                        onChange={(e) => setBillNumber(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="billDate">Bill Date:</label>
                    <input
                        type="date"
                        id="billDate"
                        value={billDate}
                        onChange={(e) => setBillDate(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="billAmount">Bill Amount:</label>
                    <input
                        type="number"
                        id="billAmount"
                        value={billAmount}
                        onChange={(e) => setBillAmount(e.target.value)}
                    />
                </div>
                <button className="add-bill-modal-button" onClick={handleSave}>Save</button>
                <button className="add-bill-modal-button" onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
};

export default AddBillModal;
