import React, { useState, useEffect } from 'react';
import './SuperAdminSystemDashboard.css';

const SuperAdminSystemDashboard: React.FC = () => {
    const [pointsConversion, setPointsConversion] = useState<number | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [newPointsConversion, setNewPointsConversion] = useState<number>(0);

    useEffect(() => {
        const fetchPointsConversion = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/superadmin/system/points-conversion', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const data = await response.json();
                setPointsConversion(data.pointsConversion);
            } catch (error) {
                console.error('Error fetching points conversion:', error);
            }
        };

        fetchPointsConversion();
    }, []);

    const handleEditClick = () => {
        setNewPointsConversion(pointsConversion || 0);
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/superadmin/system/points-conversion', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ pointsConversion: newPointsConversion })
            });
            if (response.ok) {
                setPointsConversion(newPointsConversion);
                setIsEditing(false);
            } else {
                console.error('Failed to update points conversion');
            }
        } catch (error) {
            console.error('Error updating points conversion:', error);
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    return (
        <div className="system-dashboard">
            <h1>System Settings</h1>
            <div className="points-conversion">
                <label>Points Conversion Value: </label>
                {isEditing ? (
                    <>
                        <input
                            type="number"
                            value={newPointsConversion}
                            onChange={(e) => setNewPointsConversion(Number(e.target.value))}
                        />
                        <button onClick={handleSaveClick}>Save</button>
                        <button onClick={handleCancelClick}>Cancel</button>
                    </>
                ) : (
                    <>
                        <span>{pointsConversion}</span>
                        <button onClick={handleEditClick}>Edit</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default SuperAdminSystemDashboard;
